import React, { useState, useEffect, useRef, useCallback } from "react";
import { Stage, Rect, Layer, Image as KonvaImage, Transformer, Text } from "react-konva";
import WebFont from "webfontloader";
import { useDrop } from "react-dnd";

const maxCanvasWidth = 600; // Maximum canvas width
const maxCanvasHeight = 600; // Maximum canvas height

const imageCache = {};

const useImage = (src) => {
	const [image, setImage] = useState(imageCache[src] || null);

	useEffect(() => {
		if (imageCache[src]) {
			setImage(imageCache[src]);
			return;
		}

		const img = new Image();
		img.onload = () => {
			imageCache[src] = img;
			setImage(img);
		};
		img.onerror = (err) => console.error("Failed to load image:", err);
		img.src = src;
		// No cleanup needed here as we want the loaded images to stay in cache
	}, [src]);

	return image;
};

const MemeGenerator = () => {
	const [backgroundSrc] = useState(null);
	const [selectedId, setSelectedId] = useState(null);
	const backgroundImage = useImage(backgroundSrc);
	const [elements, setElements] = useState([]);
	const stageRef = useRef(null);
	const [isModalOpen] = useState(false);
	const [isAssetModalOpen, setIsAssetModalOpen] = useState(true);
	const [isMemeModalOpen, setIsMemeModalOpen] = useState(false);
	const [textElements, setTextElements] = useState([]);
	const [originalFileName, setOriginalFileName] = useState("");
	const [showTransformer, setShowTransformer] = useState(true);
	const [editingState, setEditingState] = useState({
		visible: false,
		x: 0,
		y: 0,
		value: "",
		id: null,
	});
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
	const [showAdditionalButtons, setShowAdditionalButtons] = useState(!isMobile);
	const [canvasSize, setCanvasSize] = useState({ width: 600, height: 600 });
	const [canvasTextStyle, setCanvasTextStyle] = useState({
		fontSize: "25px",
		color: "#6eb6c8",
		textAlign: "center",
		marginBottom: "10px",
		paddingLeft: "150px",
	});
	const [assetSize, setAssetSize] = useState({ width: 150, height: 75 });
	const [memeSize, setMemeSize] = useState({ width: 150, height: 75 });
	const mobileCanvasSize = Math.min(window.innerWidth, window.innerHeight) * 0.95;
	const [searchTerms, setSearchTerms] = useState({
		asset: "",
		meme: "",
	});
	const [backgroundColor, setBackgroundColor] = useState("");
	const colorInputRef = useRef(null);
	const handleOpenColorPicker = () => {
		colorInputRef.current.click();
	};
	// Function to update background color based on the user's selection
	const handleColorChange = (event) => {
		setBackgroundColor(event.target.value);
	};
	// Example static images

	const assetImages = [
		{ url: "./images/assets/shapero head.png", name: "shapero head" },
		{ url: "./images/assets/shapero suit.png", name: "shapero suit" },
		{ url: "./images/assets/$gretch body left.png", name: "$gretch purple jacket" },
		{ url: "./images/assets/$gretch head left glasses.png", name: "$gretch glasses" },
		{ url: "./images/assets/$gretch head right.png", name: "$gretch head" },
		{ url: "./images/assets/$gretch joker.png", name: "$gretch joker" },
		{ url: "./images/assets/$gretch joker jacket.png", name: "$gretch joker jacket" },
		{ url: "./images/assets/btc.png", name: "btc" },
		{ url: "./images/assets/eth.png", name: "eth" },
		{ url: "./images/assets/sol.png", name: "sol" },
		{ url: "./images/assets/usdc.png", name: "usdc" },
		{ url: "./images/assets/usdt.png", name: "usdt" },
		{ url: "./images/assets/phantom.png", name: "phantom" },
		{ url: "./images/assets/mog.png", name: "mog" },
		{ url: "./images/assets/mog_2.png", name: "mog" },
		{ url: "./images/assets/mog_sol.png", name: "mog sol" },
		{ url: "./images/assets/wif.png", name: "wif" },
	];
	const memeTemplates = [
		{ url: "./images/templates/shapero eagle.jpg", name: "shapero eagle" },
		{ url: "./images/templates/erect shapero.jpg", name: "erect shapero" },
		{ url: "./images/templates/shapero in biden out.jpg", name: "shapero biden" },
		{ url: "./images/templates/shapero podeum.jpg", name: "shapero podeum" },
		{ url: "./images/templates/$gretch ww.jpg", name: "$gretch ww" },
		{ url: "./images/templates/$gretch stripping.jpg", name: "$gretch stripping" },
		{ url: "./images/templates/$gretch on moon.jpg", name: "$gretch on moon" },
		{ url: "./images/templates/$gretch standing pistol.jpg", name: "$gretch standing pistol" },
		{ url: "./images/templates/$gretch super hero.jpg", name: "$gretch super hero" },
		{ url: "./images/templates/$gretch monk.jpg", name: "$gretch monk" },
		{ url: "./images/templates/$gretch snipes boden.jpg", name: "$gretch snipes boden" },
		{ url: "./images/templates/$gretch pays homeless.jpg", name: "$gretch pays homeless" },
		{ url: "./images/templates/$gretch ww -2.jpg", name: "$gretch ww -2" },
		{ url: "./images/templates/$gretch teaches boden.jpg", name: "$gretch teaches boden" },
		{ url: "./images/templates/$gretch comic.jpg", name: "$gretch comic" },
		{ url: "./images/templates/$gretch wonder woman.jpg", name: "$gretch wonder woman" },
		{ url: "./images/templates/$gretch laying sniper.jpg", name: "$gretch laying sniper" },
		{ url: "./images/templates/$gretch oval office.jpg", name: "$gretch oval office" },
		{ url: "./images/templates/$gretch behind boden.jpg", name: "$gretch behind boden" },
		{ url: "./images/templates/$gretch yoga.jpg", name: "$gretch yoga" },
		{ url: "./images/templates/$gretch noahs ark.jpg", name: "$gretch noahs ark" },
		{ url: "./images/templates/$gretch flying.jpg", name: "$gretch flying" },
		{ url: "./images/templates/$gretch fishing.jpg", name: "$gretch fishing" },
		{ url: "./images/templates/$gretch wrecking ball.jpg", name: "$gretch wrecking ball" },
		{ url: "./images/templates/$gretch joker stairs.jpg", name: "$gretch joker stairs" },
		{ url: "./images/templates/$gretch $kama.jpg", name: "$gretch $kama" },
		{ url: "./images/templates/$gretch us flag.jpg", name: "$gretch us flag" },
		{ url: "./images/templates/$gretch 4th of july.jpg", name: "$gretch 4th of july" },
		{ url: "./images/templates/$gretch joker nurse.jpg", name: "$gretch joker nurse" },
		{ url: "./images/templates/$gretch $100.jpg", name: "$gretch $100" },
		{ url: "./images/templates/$gretch lock in.jpg", name: "$gretch lock in" },
		{ url: "./images/templates/$gretch time.jpg", name: "$gretch time" },
		{ url: "./images/templates/$gretch us flag 2.jpg", name: "$gretch us flag 2" },
		{ url: "./images/templates/$gretch time 2.jpg", name: "$gretch time 2" },
		{ url: "./images/templates/Bernie.jpg", name: "bernie" },
		{ url: "./images/templates/Mocking-Spongebob.jpg", name: "spongebob" },
		{ url: "./images/templates/1op9wy.jpg", name: "whisper" },
		{ url: "./images/templates/1yz6z4.jpg", name: "headache" },
		{ url: "./images/templates/1.png", name: "slap flex" },
		{ url: "./images/templates/2tzo2k.jpg", name: "soldier" },
		{ url: "./images/templates/2xscjb.png", name: "red hair" },
		{ url: "./images/templates/3kwur5.jpg", name: "homies" },
		{ url: "./images/templates/3nx72a.png", name: "grave" },
		{ url: "./images/templates/3pdf2w.png", name: "bernie" },
		{ url: "./images/templates/3vfrmx.jpg", name: "undertaker wrestling" },
		{ url: "./images/templates/4fhsie.png", name: "chart wojack" },
		{ url: "./images/templates/4pn1an.png", name: "wojack corner party" },
		{ url: "./images/templates/5c7lwq.png", name: "right" },
		{ url: "./images/templates/8fhy2l.png", name: "travis kelce chiefs" },
		{ url: "./images/templates/19vcz0.jpg", name: "pawn stars" },
		{ url: "./images/templates/seal_me_when_the.png", name: "sappy seal" },
		{ url: "./images/templates/46hhvr.jpg", name: "underwater skeleton kid" },
		{ url: "./images/templates/54hjww.jpg", name: "receive" },
		{ url: "./images/templates/145qvv.jpg", name: "spongebob patrick squidward" },
		{ url: "./images/templates/434i5j.png", name: "train bus" },
		{ url: "./images/templates/Always-Has-Been.png", name: "always has been" },
		{
			url: "./images/templates/American-Chopper-Argument.jpg",
			name: "american chopper argue",
		},
		{ url: "./images/templates/Ancient-Aliens.jpg", name: "ancient aliens" },
		{ url: "./images/templates/Batman-Slapping-Robin.jpg", name: "batman robin" },
		{ url: "./images/templates/Bike-Fall.jpg", name: "bike fall" },
		{ url: "./images/templates/Blank-Nut-Button.jpg", name: "button nut" },
		{ url: "./images/templates/Change-My-Mind.jpg", name: "change my mind" },
		{ url: "./images/templates/Clown-Applying-Makeup.jpg", name: "clown" },
		{ url: "./images/templates/Disaster-Girl.jpg", name: "fire disaster girl" },
		{
			url: "./images/templates/Distracted-Boyfriend.jpg",
			name: "distracted boyfriend",
		},
		{ url: "./images/templates/drake.jpeg", name: "drake" },
		{ url: "./images/templates/Epic-Handshake.jpg", name: "handshake bicept" },
		{ url: "./images/templates/Evil-Kermit.jpg", name: "kermit evil" },
		{ url: "./images/templates/Expanding-Brain.jpg", name: "brain" },
		{ url: "./images/templates/Futurama-Fry.jpg", name: "futurama fry" },
		{
			url: "./images/templates/Grandma-Finds-The-Internet.jpg",
			name: "grandma computer",
		},
		{ url: "./images/templates/Grus-Plan.jpg", name: "gru" },
		{
			url: "./images/templates/Hide-the-Pain-Harold.jpg",
			name: "pain diabetes harold",
		},
		{
			url: "./images/templates/I-Bet-Hes-Thinking-About-Other-Women.jpg",
			name: "bet thninking about",
		},
		{ url: "./images/templates/Inhaling-Seagull.jpg", name: "seagull" },
		{ url: "./images/templates/Is-This-A-Pigeon.jpg", name: "is this a" },
		{ url: "./images/templates/Laughing-Leo.png", name: "laugh leonardo dicaprio" },
		{ url: "./images/templates/Left-Exit-12-Off-Ramp.jpg", name: "exit ramp" },
		{ url: "./images/templates/Monkey-Puppet.jpg", name: "monkey puppet" },
		{ url: "./images/templates/One-Does-Not-Simply.jpg", name: "one does not simply" },
		{ url: "./images/templates/Oprah-You-Get-A.jpg", name: "oprah you get a" },
		{ url: "./images/templates/Panik-Kalm-Panik.png", name: "panik kalm" },
		{
			url: "./images/templates/Roll-Safe-Think-About-It.jpg",
			name: "roll safe think about it smart big brain",
		},
		{ url: "./images/templates/Running-Away-Balloon.jpg", name: "run away balloon" },
		{ url: "./images/templates/Sad-Pablo-Escobar.jpg", name: "pablo escobar" },
		{ url: "./images/templates/Sleeping-Shaq.jpg", name: "shaq" },
		{ url: "./images/templates/Success-Kid.jpg", name: "yes success kid" },
		{ url: "./images/templates/seal_me_when_the.png", name: "sappy seal" },
		{
			url: "./images/templates/The-Rock-Driving.jpg",
			name: "the rock dwayne johnson",
		},
		{ url: "./images/templates/Same-Picture.jpg", name: "difference same explain" },
		{
			url: "./images/templates/Third-World-Skeptical-Kid.jpg",
			name: "skeptikal kid",
		},
		{ url: "./images/templates/This-Is-Fine.jpg", name: "this is gine" },
		{ url: "./images/templates/Tuxedo-Winnie-The-Pooh.png", name: "whinnie the pooh" },
		{ url: "./images/templates/Two-Buttons.jpg", name: "buttons" },
		{ url: "./images/templates/u0pf0.jpg", name: "laughing disappointed" },
		{ url: "./images/templates/UNO-Draw-25-Cards.jpg", name: "uno draw 25" },
		{ url: "./images/templates/Waiting-Skeleton.jpg", name: "skeleton waiting" },
		{ url: "./images/templates/Who-Killed-Hannibal.jpg", name: "who killed hannibal" },
		{ url: "./images/templates/Woman-Yelling-At-Cat.jpg", name: "woman yelling cat" },
		{
			url: "./images/templates/X-X-Everywhere.jpg",
			name: "woody buzz lightyear everywhere",
		},
		{
			url: "./images/templates/Yall-Got-Any-More-Of-That.jpg",
			name: "dave chapelle yall got anymore",
		},
	];

	useEffect(() => {
		WebFont.load({
			google: {
				families: ["Anton"],
			},
			active: () => {
				// Force update on font load to re-render the text with the loaded font
				setElements((prevElements) => [...prevElements]);
			},
		});
	}, []); // Empty dependency array means this effect runs once on component mount

	const filteredAssetImages = searchTerms.asset
		? assetImages.filter((image) =>
				image.name.toLowerCase().includes(searchTerms.asset.toLowerCase())
		  )
		: assetImages;

	const filteredMemeTemplates = searchTerms.meme
		? memeTemplates.filter((image) =>
				image.name.toLowerCase().includes(searchTerms.meme.toLowerCase())
		  )
		: memeTemplates;

	const openAssetModal = () => {
		setIsAssetModalOpen(!isAssetModalOpen);
		if (isAssetModalOpen === false) {
			setIsMemeModalOpen(false);
		}
	};

	const openMemeModal = () => {
		setIsMemeModalOpen(!isMemeModalOpen);
		if (isMemeModalOpen === false) {
			setIsAssetModalOpen(false);
		}
	};

	const toggleAdditionalButtons = () => {
		setShowAdditionalButtons(!showAdditionalButtons);
	};

	// Adjustments for Mobile
	useEffect(() => {
		function adjustCanvasMobile() {
			if (window.innerWidth <= 1024 || window.innerHeight.height <= 1024) {
				setIsMobile(true);
				setCanvasTextStyle({
					fontSize: "20px",
					color: "#6eb6c8",
					textAlign: "center",
					marginBottom: "10px",
					paddingLeft: "0px",
				});
				setAssetSize({ width: 70, height: 70 });
				setMemeSize({ width: 70, height: 70 });
				setCanvasSize({ width: mobileCanvasSize, height: mobileCanvasSize });
			} else {
				setIsMobile(false);
				setCanvasTextStyle({
					fontSize: "25px",
					color: "#6eb6c8",
					textAlign: "center",
					marginBottom: "10px",
					paddingLeft: "150px",
				});
				setAssetSize({ width: 150, height: 150 });
				setMemeSize({ width: 150, height: 150 });
				setCanvasSize({ width: 600, height: 600 }); // Default size for larger screens
			}
		}
		adjustCanvasMobile();
		window.addEventListener("resize", adjustCanvasMobile);

		// Cleanup listener on component unmount
		return () => window.removeEventListener("resize", adjustCanvasMobile);
	}, [mobileCanvasSize]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if ((event.key === "Backspace" || event.key === "Delete") && selectedId) {
				if (
					document.activeElement.tagName !== "INPUT" &&
					document.activeElement.tagName !== "TEXTAREA"
				) {
					event.preventDefault();
					deleteSelectedImage();
				}
			}
		};

		document.addEventListener("keydown", handleKeyDown);

		return () => document.removeEventListener("keydown", handleKeyDown);
	}, [selectedId]);

	// Function to add a new text box to canvas
	const addTextElement = () => {
		const newTextElement = {
			text: "Your Text",
			x: 50,
			y: 50,
			fontSize: 35,
			id: Math.random().toString(36).substr(2, 9),
			draggable: true,
			color: "white",
		};
		setTextElements(textElements.concat(newTextElement));
		setSelectedId(newTextElement.id);
	};

	const resetCanvas = () => {
		setElements([]);
		setTextElements([]);
		setSelectedId(null);
		setBackgroundColor("");
		if (!isMobile) {
			adjustCanvasSize(600, 600);
		} else {
			adjustCanvasSize(mobileCanvasSize, mobileCanvasSize);
		}
	};

	const adjustCanvasSize = (imgWidth, imgHeight) => {
		let canvasWidth = imgWidth;
		let canvasHeight = imgHeight;

		// Calculate the scale factors for width and height
		const widthScale = maxCanvasWidth / imgWidth;
		const heightScale = maxCanvasHeight / imgHeight;
		const scale = Math.min(widthScale, heightScale, 1);

		// Adjust canvas size with scale factor, not exceeding maximum dimensions
		canvasWidth = imgWidth * scale;
		canvasHeight = imgHeight * scale;

		// Update canvas dimensions
		stageRef.current.width(canvasWidth);
		stageRef.current.height(canvasHeight);
	};

	// Edit text on canvas
	const handleTextEdit = (id) => {
		const textEl = textElements.find((te) => te.id === id);
		if (!textEl) return;

		const textPosition = stageRef.current.findOne(`#${id}`).getAbsolutePosition();
		const stageBox = stageRef.current.container().getBoundingClientRect();
		const areaPosition = {
			x: stageBox.left + textPosition.x,
			y: stageBox.top + textPosition.y,
		};

		const textarea = document.createElement("textarea");
		document.body.appendChild(textarea);
		textarea.value = textEl.text;
		textarea.style.position = "absolute";
		textarea.style.top = areaPosition.y + "px";
		textarea.style.left = areaPosition.x + "px";
		textarea.style.width = textEl.width + "px";
		textarea.focus();

		let removeTimeout;

		const removeTextarea = () => {
			const newText = textarea.value.trim();
			const updatedTextElements = textElements.map((te) => {
				if (te.id === id) {
					return { ...te, text: newText };
				}
				return te;
			});

			setTextElements(updatedTextElements);
			if (textarea.parentNode) {
				document.body.removeChild(textarea);
			}
		};

		textarea.addEventListener("keydown", function (e) {
			clearTimeout(removeTimeout);
			if (e.key === "Enter") {
				removeTextarea();
				e.preventDefault();
			}
		});

		textarea.addEventListener("blur", function () {
			removeTimeout = setTimeout(removeTextarea, 0);
		});
	};

	const saveText = () => {
		const updatedTextElements = textElements.map((el) => {
			if (el.id === editingState.id) {
				return { ...el, text: editingState.value };
			}
			return el;
		});

		setTextElements(updatedTextElements);
		setEditingState({ visible: false, x: 0, y: 0, value: "", id: null });
	};

	const DraggableImage = React.memo(
		({
			src,
			x,
			y,
			width,
			height,
			rotation,
			onDragEnd,
			id,
			onSelect,
			isSelected,
			onResize,
			flipX,
			flipY,
			draggable,
		}) => {
			const image = useImage(src);
			const offsetX = flipX ? width / 2 : 0;
			const offsetY = flipY ? height / 2 : 0;

			return image ? (
				<KonvaImage
					image={image}
					x={x}
					y={y}
					width={width}
					height={height}
					offsetX={offsetX}
					offsetY={offsetY}
					rotation={rotation}
					scaleX={flipX ? -1 : 1}
					scaleY={flipY ? -1 : 1}
					draggable={draggable}
					onMouseDown={(e) => {
						onSelect(id);
						e.cancelBubble = true;
					}}
					onDragEnd={(e) => handleDragEnd(e, id)}
					onTransformEnd={(e) => handleTransformEnd(e, id)}
					onTap={(e) => {
						onSelect(id); // Use the same onSelect logic for tap as you do for mouse down
						e.cancelBubble = true;
					}}
					onTouchStart={(e) => {
						// This ensures that touch events don't trigger scrolling or zooming
						e.evt.preventDefault();
						onSelect(id);
					}}
					id={id}
				/>
			) : null;
		}
	);

	const handleResize = React.memo((id, newWidth, newHeight) => {
		setElements((prevElements) =>
			prevElements.map((element) => {
				if (element.id === id) {
					return {
						...element,
						width: newWidth,
						height: newHeight,
						scaleX: 1,
						scaleY: 1,
					};
				}
				return element;
			})
		);
	});

	const handleTransformEnd = useCallback((e, id) => {
		const node = e.target;
		const scaleX = node.scaleX();
		const scaleY = node.scaleY();
		const rotation = node.rotation();
		const x = node.x();
		const y = node.y();

		const newWidth = node.width() * scaleX;
		const newHeight = node.height() * scaleY;
		node.scaleX(1);
		node.scaleY(1);

		setElements((prevElements) =>
			prevElements.map((el) => {
				if (el.id === id) {
					return {
						...el,
						x,
						y,
						rotation,
						width: Math.abs(newWidth),
						height: Math.abs(newHeight),
						scaleX,
						scaleY,
						flipX: scaleX < 0,
						flipY: scaleY < 0,
					};
				}
				return el;
			})
		);
	}, []);

	const handleSelect = useCallback((id) => {
		setSelectedId(id);
	}, []);
	useEffect(() => {}, [selectedId]);

	const [, drop] = useDrop(() => ({
		accept: "image",
		drop: (item, monitor) => {
			const clientOffset = monitor.getClientOffset();
			const stageBox = stageRef.current.container().getBoundingClientRect();
			const x = clientOffset.x - stageBox.left;
			const y = clientOffset.y - stageBox.top;
			addElementToCanvas(item.url, x, y);
		},
	}));

	const addElementToCanvas = (url, x, y, type) => {
		const img = new Image();
		img.onload = () => {
			let maxWidth, maxHeight;

			// Define maximum dimensions for each type
			switch (type) {
				case "asset":
					maxWidth = isMobile ? 120 : 200;
					maxHeight = isMobile ? 120 : 200;
					break;
				case "meme":
					maxWidth = isMobile ? mobileCanvasSize : 600;
					maxHeight = isMobile ? mobileCanvasSize : 600;
					resetCanvas();
					break;
				default:
					maxWidth = 150;
					maxHeight = 150;
			}

			const aspectRatio = img.width / img.height;
			let newWidth = img.width;
			let newHeight = img.height;

			if (newWidth > maxWidth) {
				newWidth = maxWidth;
				newHeight = newWidth / aspectRatio;
			}

			if (newHeight > maxHeight) {
				newHeight = maxHeight;
				newWidth = newHeight * aspectRatio;
			}

			const newItem = {
				src: url,
				x: x,
				y: y,
				width: newWidth,
				height: newHeight,
				id: Math.random().toString(36).substr(2, 9),
				type: type,
				rotation: 0,
				draggable: true,
			};

			setElements((prevElements) => [...prevElements, newItem]);
			setSelectedId(newItem.id);
		};
		img.src = url;
	};

	const handleDragEnd = useCallback(
		(e, id) => {
			const index = elements.findIndex((el) => el.id === id);
			if (index === -1) return;

			const node = e.target;
			let newX = node.x();
			let newY = node.y();
			const scaleX = node.scaleX();
			const scaleY = node.scaleY();
			const newRotation = node.rotation();
			const newWidth = node.width() * Math.abs(scaleX);
			const newHeight = node.height() * Math.abs(scaleY);
			const newFlipX = node.scaleX() < 0;
			const newFlipY = node.scaleY() < 0;

			const newElements = elements.map((el, i) => {
				if (i === index) {
					return {
						...el,
						x: newX,
						y: newY,
						width: newWidth,
						height: newHeight,
						rotation: newRotation,
						flipX: newFlipX,
						flipY: newFlipY,
					};
				}
				return el;
			});

			setElements(newElements);
		},
		[elements]
	);

	const flipElementHorizontal = (id) => {
		setElements((prevElements) =>
			prevElements.map((el) => {
				if (el.id === id) {
					return { ...el, flipX: !el.flipX };
				}
				return el;
			})
		);
	};

	const flipElementVertical = (id) => {
		setElements((prevElements) =>
			prevElements.map((el) => {
				if (el.id === id) {
					return { ...el, flipY: !el.flipY };
				}
				return el;
			})
		);
	};

	const handleBackgroundUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			resetCanvas();
			setOriginalFileName(file.name);

			const reader = new FileReader();
			reader.onloadend = () => {
				const src = reader.result;
				resetCanvas();
				addBackgroundToCanvas(src);
			};
			reader.readAsDataURL(file);

			event.target.value = "";
		}
	};

	const handleAssetUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			const relevantElement = elements.find((el) => el.id === "background" || el.type === "meme");

			if (!relevantElement && !backgroundColor) {
				alert("Please upload a background image or a meme before downloading.");
				console.error("Background image or meme not found");
				event.target.value = "";
				return;
			}

			setOriginalFileName(file.name);

			const reader = new FileReader();
			reader.onloadend = () => {
				const src = reader.result;
				addAssetToCanvas(src);
				event.target.value = "";
			};
			reader.readAsDataURL(file);
		}
	};

	const addBackgroundToCanvas = (src) => {
		const img = new Image();
		img.onload = () => {
			let size;
			let maxImageWidth = 0;
			let maxImageHeight = 0;
			if (!isMobile) {
				maxImageWidth = 600;
				maxImageHeight = 600;
			} else {
				maxImageWidth = mobileCanvasSize;
				maxImageHeight = mobileCanvasSize;
			}
			const aspectRatio = img.width / img.height;
			let imageWidth = img.width;
			let imageHeight = img.height;

			if (imageWidth > maxImageWidth) {
				imageWidth = maxImageWidth;
				imageHeight = imageWidth / aspectRatio;
			}
			if (imageHeight > maxImageHeight) {
				imageHeight = maxImageHeight;
				imageWidth = imageHeight * aspectRatio;
			}
			size = { width: imageWidth, height: imageHeight };

			setElements([
				{
					src,
					x: 0,
					y: 0,
					width: size.width,
					height: size.height,
					id: "background",
					draggable: false,
				},
			]);
		};
		img.src = src;
	};

	const addAssetToCanvas = (src) => {
		const img = new Image();
		img.onload = () => {
			const canvasWidth = stageRef.current.width();
			const canvasHeight = stageRef.current.height();

			const scaleX = canvasWidth / img.width;
			const scaleY = canvasHeight / img.height;
			const scale = Math.min(scaleX, scaleY, 1);

			const scaledWidth = img.width * scale;
			const scaledHeight = img.height * scale;

			setElements((prevElements) => [
				...prevElements,
				{
					src,
					x: 0,
					y: 0,
					width: scaledWidth,
					height: scaledHeight,
					id: Math.random().toString(36).substr(2, 9),
				},
			]);
		};
		img.src = src;
	};

	const increaseSize = () => {
		if (!selectedId) return;

		setElements((prevElements) =>
			prevElements.map((element) => {
				if (element.id === selectedId) {
					if (element.src) {
						const newWidth = element.width * 1.1;
						const newHeight = element.height * 1.1;
						return {
							...element,
							width: newWidth,
							height: newHeight,
						};
					}
				}
				return element;
			})
		);

		setTextElements((prevTextElements) =>
			prevTextElements.map((textElement) => {
				if (textElement.id === selectedId) {
					if (textElement.fontSize) {
						return {
							...textElement,
							fontSize: textElement.fontSize + 2,
						};
					}
				}
				return textElement;
			})
		);
	};

	const decreaseSize = () => {
		if (!selectedId) return;

		setTextElements((prevTextElements) =>
			prevTextElements.map((textElement) => {
				if (textElement.id === selectedId) {
					return {
						...textElement,
						fontSize: Math.max(textElement.fontSize - 2, 1),
					};
				}
				return textElement;
			})
		);

		setElements((prevElements) =>
			prevElements.map((element) => {
				if (element.id === selectedId) {
					const newWidth = element.width * 0.9;
					const newHeight = element.height * 0.9;
					return { ...element, width: newWidth, height: newHeight };
				}
				return element;
			})
		);
	};

	const TransformerComponent = React.memo(({ selectedId }) => {
		const transformerRef = useRef();

		useEffect(() => {
			const timeout = setTimeout(() => {
				if (transformerRef.current && stageRef.current) {
					const selectedNode = stageRef.current.findOne(`#${selectedId}`);
					if (selectedNode) {
						transformerRef.current.nodes([selectedNode]);
						if (selectedNode.className === "Text") {
							transformerRef.current.enabledAnchors([
								"top-left",
								"top-right",
								"bottom-left",
								"bottom-right",
							]);
						} else {
							transformerRef.current.enabledAnchors([
								"top-left",
								"top-right",
								"bottom-left",
								"bottom-right",
							]);
						}
					} else {
						transformerRef.current.nodes([]);
					}
				}
			}, 75);

			return () => clearTimeout(timeout);
		}, [selectedId]);
		if (!showTransformer) return null;
		return (
			<Transformer
				ref={transformerRef}
				keepRatio={false}
				borderStroke="#2d9bbd"
				borderStrokeWidth={3}
				anchorSize={8}
				anchorStroke="black"
				anchorFill="#2d9bbd"
				rotateEnabled={true}
			/>
		);
	});

	const moveElementInArray = (array, fromIndex, toIndex) => {
		const newArray = [...array];
		const element = newArray.splice(fromIndex, 1)[0];
		newArray.splice(toIndex, 0, element);
		return newArray;
	};

	const moveElementUp = () => {
		if (selectedId) {
			const selectedIndex = elements.findIndex((element) => element.id === selectedId);
			if (selectedIndex > 0) {
				const updatedElements = moveElementInArray(elements, selectedIndex, selectedIndex - 1);
				setElements(updatedElements);
			}
		}
	};

	const moveElementDown = () => {
		if (selectedId) {
			const selectedIndex = elements.findIndex((element) => element.id === selectedId);
			if (selectedIndex < elements.length - 1) {
				const updatedElements = moveElementInArray(elements, selectedIndex, selectedIndex + 1);
				setElements(updatedElements);
			}
		}
	};

	const deleteSelectedImage = () => {
		let deleted = false;

		const isImageElement = elements.some((element) => element.id === selectedId);
		if (isImageElement) {
			const newElements = elements.filter((element) => element.id !== selectedId);
			setElements(newElements);
			deleted = true;
		} else {
			const newTextElements = textElements.filter((textElement) => textElement.id !== selectedId);
			setTextElements(newTextElements);
			deleted = newTextElements.length !== textElements.length;
		}

		setSelectedId(null);

		if (deleted && elements.length === 0 && textElements.length === 0) {
			adjustCanvasSize(600, 600);
		}
	};

	const toggleTextColor = () => {
		setTextElements(
			textElements.map((textElement) => {
				if (textElement.id === selectedId) {
					const newColor = textElement.color === "black" ? "white" : "black";
					return {
						...textElement,
						color: newColor,
					};
				}
				return textElement;
			})
		);
	};

	const handleDownloadMergedImage = () => {
		setShowTransformer(false);

		const relevantElement = elements.find((el) => el.id === "background" || el.type === "meme");

		if (!relevantElement && !backgroundColor) {
			alert(
				"Please upload a background image, select a meme, or choose a background color before downloading."
			);
			console.error("Background image, meme, or color not found");
			setShowTransformer(true);
			return;
		}

		setTimeout(() => {
			const stage = stageRef.current.getStage();

			const minX = 0,
				minY = 0;
			const maxX = relevantElement ? Math.min(stage.width(), relevantElement.width) : stage.width();
			const maxY = relevantElement
				? Math.min(stage.height(), relevantElement.height)
				: stage.height();

			const contentWidth = maxX - minX;
			const contentHeight = maxY - minY;

			const dataURL = stage.toDataURL({
				pixelRatio: 2,
				x: minX,
				y: minY,
				width: contentWidth,
				height: contentHeight,
			});

			const link = document.createElement("a");
			link.download = originalFileName ? `meme_${originalFileName}` : "meme_merged_image.png";
			link.href = dataURL;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			setTimeout(() => {
				stage.batchDraw();
				setShowTransformer(true);
			}, 100);
		}, 100);
	};

	const Modal = ({ isOpen, close, children }) => {
		if (!isOpen) return null;
		return (
			<div className="modal-overlay" onClick={close}>
				<div className="modal-content" onClick={(e) => e.stopPropagation()}>
					{children}
				</div>
			</div>
		);
	};

	const renderAssetModalContent = () =>
		filteredAssetImages.map((image, index) => (
			<div key={`asset-${index}`} style={{ textAlign: "center", margin: "5px" }}>
				<button
					onClick={() => addElementToCanvas(image.url, assetSize.width, assetSize.height, "asset")}
				>
					<img
						src={image.url}
						alt={image.name}
						style={{
							maxWidth: `${assetSize.width}px`,
							maxHeight: `${assetSize.height}px`,
							objectFit: "contain",
						}}
					/>
				</button>
				<div>{image.name}</div>
			</div>
		));

	// function shuffleArray(array) {
	// 	for (let i = array.length - 1; i > 0; i--) {
	// 		const j = Math.floor(Math.random() * (i + 1));
	// 		[array[i], array[j]] = [array[j], array[i]]; // Swap elements
	// 	}
	// 	return array;
	// }

	const renderMemeModalContent = () => {
		// const shuffledMemeTemplates = shuffleArray([...filteredMemeTemplates]);
		return filteredMemeTemplates.map((image, index) => (
			<div key={`meme-${index}`} style={{ textAlign: "center", margin: "5px" }}>
				<button onClick={() => addElementToCanvas(image.url, 0, 0, "meme")}>
					<img
						src={image.url}
						alt=""
						style={{
							maxWidth: `${memeSize.width}px`,
							maxHeight: `${memeSize.height}px`,
							objectFit: "contain",
						}}
					/>
				</button>
			</div>
		));
	};

	useEffect(() => {}, [isModalOpen]);

	return (
		<div className="meme-container">
			{!isMobile && (
				<div style={canvasTextStyle}>
					{(isAssetModalOpen || isMemeModalOpen) && (
						<input
							className="search-text"
							type="text"
							placeholder={`Search ${isAssetModalOpen ? "assets" : "memes"}...`}
							style={{ marginLeft: "10px" }}
							value={isAssetModalOpen ? searchTerms.asset : searchTerms.meme}
							onChange={(e) =>
								setSearchTerms({
									...searchTerms,
									[isAssetModalOpen ? "asset" : "meme"]: e.target.value,
								})
							}
						/>
					)}
				</div>
			)}
			<div className="canvas-toolbar-container">
				{!isMobile && (
					<div className="button-toolbar">
						<div className="primary-buttons-container">
							<input
								className="color-input"
								ref={colorInputRef}
								type="color"
								style={{ display: "none" }}
								onChange={handleColorChange}
							/>
							<button className="primary-button" onClick={handleOpenColorPicker}>
								background color
							</button>
							<input
								id="fileInput1"
								type="file"
								onChange={handleBackgroundUpload}
								accept="image/*"
								style={{ display: "none" }}
							/>
							<button
								className="primary-button"
								onClick={() => document.getElementById("fileInput1").click()}
								title="Upload background image first"
							>
								upload background
							</button>
							<input
								id="fileInput2"
								type="file"
								onChange={handleAssetUpload}
								accept="image/*"
								style={{ display: "none" }}
							/>
							<button
								className="primary-button"
								onClick={() => document.getElementById("fileInput2").click()}
								title="Must upload background image before loading custom assets"
							>
								upload asset
							</button>
							<button className="primary-button" onClick={resetCanvas}>
								reset canvas
							</button>
							<button className="primary-button" onClick={handleDownloadMergedImage}>
								download
							</button>
						</div>
						<div className="hide-button">
							<button className="primary-button" onClick={toggleAdditionalButtons}>
								{showAdditionalButtons ? "↑ hide tools" : "↓ show tools"}
							</button>
						</div>
						{showAdditionalButtons && (
							<div className="additional-buttons group-spacing">
								<button className="primary-button" onClick={addTextElement}>
									add text
								</button>
								<button className="primary-button" onClick={toggleTextColor}>
									black/white text
								</button>
								<button className="primary-button" onClick={moveElementDown}>
									layer up
								</button>
								<button className="primary-button" onClick={moveElementUp}>
									layer down
								</button>
								<button className="primary-button" onClick={increaseSize}>
									+ size
								</button>
								<button className="primary-button" onClick={decreaseSize}>
									- size
								</button>
								<button
									className="primary-button"
									onClick={() => selectedId && flipElementHorizontal(selectedId)}
								>
									flip horizontal
								</button>
								<button
									className="primary-button"
									onClick={() => selectedId && flipElementVertical(selectedId)}
								>
									flip vertical
								</button>
								<button className="primary-button" onClick={deleteSelectedImage}>
									<img
										src="./images/trash.png"
										alt="Backward"
										style={{ width: "25px", height: "25px" }}
									/>
								</button>
							</div>
						)}
					</div>
				)}
				{/* Canvas */}
				<div ref={drop} className="canvas-frame">
					<Stage
						width={canvasSize.width}
						height={canvasSize.height}
						pixelRatio={window.devicePixelRatio}
						ref={stageRef}
						onMouseDown={(e) => {
							// Check if the click is on the stage or the background image
							if (e.target === e.target.getStage() || e.target.name() === "background") {
								setSelectedId(null);
							}
						}}
					>
						<Layer>
							<Rect
								x={0}
								y={0}
								width={canvasSize.width}
								height={canvasSize.height}
								fill={backgroundColor}
							/>
							{backgroundImage && (
								<KonvaImage
									image={backgroundImage}
									name="background"
									width={canvasSize.width}
									height={canvasSize.height}
									draggable={false}
								/>
							)}

							{elements.map((element, i) => (
								<DraggableImage
									key={i}
									src={element.src}
									x={element.x}
									y={element.y}
									width={element.width}
									height={element.height}
									rotation={element.rotation || 0}
									onDragEnd={(e) => handleDragEnd(e, i)}
									isSelected={element.id === selectedId}
									onSelect={handleSelect}
									id={element.id}
									onResize={handleResize}
									draggable={element.id !== "background" && element.type !== "meme"}
									flipX={element.flipX || false}
									flipY={element.flipY || false}
									scaleX={element.flipX ? -1 : 1}
									scaleY={element.flipY ? -1 : 1}
								/>
							))}

							{textElements.map((textElement, i) => (
								<Text
									key={i}
									{...textElement}
									fontFamily="Anton"
									fill={textElement.color}
									fontSize={textElement.fontSize}
									// stroke={textElement.stroke}
									// strokeWidth={
									//     textElement.stroke === 'transparent'
									//         ? 0
									//         : 2
									// }
									// shadowColor={textElement.shadowColor}
									// shadowBlur={textElement.shadowBlur}
									// shadowOpacity={textElement.shadowOpacity}
									onClick={() => handleSelect(textElement.id)}
									onDblClick={() => handleTextEdit(textElement.id)}
									onDragEnd={(e) => {
										const updatedTextElements = textElements.map((el) => {
											if (el.id === textElement.id) {
												return {
													...el,
													x: e.target.x(),
													y: e.target.y(),
												};
											}
											return el;
										});
										setTextElements(updatedTextElements);
									}}
									onTap={() => handleSelect(textElement.id)}
									onDblTap={() => handleTextEdit(textElement.id)}
								/>
							))}

							{editingState.visible && (
								<input
									style={{
										position: "absolute",
										top: `${editingState.y}px`,
										left: `${editingState.x}px`,
										zIndex: 100,
									}}
									autoFocus
									value={editingState.value}
									onChange={(e) =>
										setEditingState({
											...editingState,
											value: e.target.value,
										})
									}
									onBlur={() => saveText()}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											saveText();
										}
									}}
								/>
							)}

							{selectedId && <TransformerComponent selectedId={selectedId} stageRef={stageRef} />}
						</Layer>
					</Stage>
					<div className="bottom-and-modal">
						{!isMobile && (
							<div className="bottom-frame-buttons">
								<button className="primary-button" onClick={openAssetModal}>
									{isAssetModalOpen ? "✔️ assets" : "assets"}
								</button>
								<button className="primary-button" onClick={openMemeModal}>
									{isMemeModalOpen ? "✔️ memes" : "memes"}
								</button>
							</div>
						)}
						{isMobile && (
							<div className="bottom-frame-buttons">
								<button className="primary-button" onClick={openAssetModal}>
									{isAssetModalOpen ? "✔️ assets" : "assets"}
								</button>
								<button className="primary-button" onClick={openMemeModal}>
									{isMemeModalOpen ? "✔️ meme" : "meme"}
								</button>
								<button className="primary-button" onClick={toggleTextColor}>
									black/white text
								</button>
								<button className="primary-button" onClick={addTextElement}>
									add text
								</button>
								<button className="primary-button" onClick={decreaseSize}>
									- size
								</button>
								<button className="primary-button" onClick={moveElementUp}>
									layer down
								</button>
								<button
									className="primary-button"
									onClick={() => selectedId && flipElementVertical(selectedId)}
								>
									flip vertical
								</button>
							</div>
						)}
						<div className="modal-mobile">
							{isMobile && (isAssetModalOpen || isMemeModalOpen) && (
								<input
									className="search-text-mobile"
									type="text"
									placeholder={`Search ${isAssetModalOpen ? "assets" : "memes"}...`}
									value={isAssetModalOpen ? searchTerms.asset : searchTerms.meme}
									onChange={(e) =>
										setSearchTerms({
											...searchTerms,
											[isAssetModalOpen ? "asset" : "meme"]: e.target.value,
										})
									}
								/>
							)}
							{isAssetModalOpen && (
								<Modal isOpen={isAssetModalOpen} close={() => setIsAssetModalOpen(false)}>
									{renderAssetModalContent()}
								</Modal>
							)}
							{isMemeModalOpen && (
								<Modal isOpen={isMemeModalOpen} close={() => setIsMemeModalOpen(false)}>
									{renderMemeModalContent()}
								</Modal>
							)}
						</div>
					</div>
				</div>
				{isMobile && (
					<div className="button-toolbar">
						<div className="primary-buttons">
							<button className="primary-button" onClick={handleDownloadMergedImage}>
								download
							</button>
							<input
								id="fileInput1"
								type="file"
								onChange={handleBackgroundUpload}
								accept="image/*"
								style={{ display: "none" }}
							/>
							<button
								className="primary-button"
								onClick={() => document.getElementById("fileInput1").click()}
								title="Upload background image first"
							>
								upload background
							</button>
							<input
								id="fileInput2"
								type="file"
								onChange={handleAssetUpload}
								accept="image/*"
								style={{ display: "none" }}
							/>
							<button
								className="primary-button"
								onClick={() => document.getElementById("fileInput2").click()}
								title="Must upload background image before loading custom assets"
							>
								upload asset
							</button>
							<button
								className="primary-button"
								onClick={resetCanvas}
								style={{ marginBottom: "30px" }}
							>
								reset canvas
							</button>
							<div className="additional-buttons">
								<button className="primary-button" onClick={increaseSize}>
									+ size
								</button>
								<button className="primary-button" onClick={moveElementDown}>
									layer up
								</button>
								<button
									className="primary-button"
									onClick={() => selectedId && flipElementHorizontal(selectedId)}
								>
									flip horizontal
								</button>
								<button className="primary-button" onClick={deleteSelectedImage}>
									<img
										src="./images/trash.png"
										alt="Backward"
										style={{ width: "25px", height: "25px" }}
									/>
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default MemeGenerator;
