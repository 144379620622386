import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import MemeGenerator from "./components/MemeGenerator";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import usePageTracking from "./components/usePageTracking"; // Import the hook

function TrackedApp() {
  usePageTracking(); // Call the hook here within a component that is a child of Router

  return (
    <div className="App">
      <MemeGenerator />
    </div>
  );
}

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <TrackedApp /> {/* Render the component that handles tracking */}
      </Router>
    </DndProvider>
  );
}

export default App;
